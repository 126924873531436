<template lang="pug">
v-data-table.clickable(
  :headers="headers",
  :loading="areProductsPending",
  no-data-text="No Products Found",
  :options.sync="options",
  :server-items-length="haveProductsLoaded ? paginationData.default.mostRecent.total : 0",
  :items="products",
  @click:row="(item) => this.user.permissions === 'SHOP_USER' ? null : $router.push({ name: 'edit-product', params: { productId: item.id } })",
  :footer-props="{ itemsPerPageOptions: [5, 10, 15] }"
)
  template(#body.prepend)
    tr
      td(colspan="100")
        v-btn(
          color="primary",
          block,
          :to="{ name: 'edit-product', params: { productId: 'new' }, query: { shopId } }"
        )
          v-icon(left) mdi-plus
          | Add Product

  template(v-slot:item.listPrice="{ item: product }")
    span(v-if="product.listPrice") ${{ product.listPrice | round(2) }}
  template(v-slot:item.costPrice="{ item: product }")
    span(v-if="product.costPrice") ${{ product.costPrice | round(2) }}
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'ProductsTable',
  props: {
    query: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    headers: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            text: 'Name',
            value: 'name'
          },
          {
            text: 'Description',
            value: 'description'
          },
          {
            text: 'Cost Price',
            value: 'costPrice'
          },
          {
            text: 'List Price',
            value: 'listPrice'
          }
        ]
      }
    },
    shopId: {
      type: String,
      required: false
    },
    search: {
      type: String,
      required: false
    }
  },
  setup (props, context) {
    const { Product } = context.root.$FeathersVuex.api

    // Basic starter datatable attributes
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true]
    })

    // Compute the sort based on various datatable properties
    const sortBy = computed(() => {
      var obj = {}
      if (options.value.sortBy && options.value.sortBy.length) {
        obj[options.value.sortBy[0]] = options.value.sortDesc[0] ? -1 : 1
      }
      return obj
    })

    const limit = computed(() => {
      if (options.value.itemsPerPage !== -1) {
        return options.value.itemsPerPage
      } else {
        return 999
      }
    })

    const queryObj = computed(() => {
      const returnQuery = {
        ...props.query,
        $limit: limit.value,
        $skip: options.value.itemsPerPage * (options.value.page - 1),
        $sort: sortBy.value
      }
      return returnQuery
    })

    // Construct the LOCAL params, these search the store
    const params = computed(() => {
      const params = { ...queryObj.value }
      if (props.search !== null && props.search !== '') {
        params.$or = [
          {
            name: {
              $regex: props.search,
              $options: 'gi'
            }
          },
          {
            description: {
              $regex: props.search,
              $options: 'gi'
            }
          }
        ]
      }

      return { query: { ...params } }
    })

    // Construct the fetch Params, these actually query the API, very confusing, I know
    const fetchParams = computed(() => {
      const params = { ...queryObj.value }

      if (props.search !== null && props.search !== '') {
        params.$or = [
          {
            name: {
              $iRegexp: props.search
            }
          },
          {
            description: {
              $iRegexp: props.search
            }
          }
        ]
      }

      return { query: { ...params } }
    })
    // Do the actual querying, be sure to remember to paginate
    const {
      items: products,
      isPending: areProductsPending,
      paginationData,
      haveLoaded: haveProductsLoaded
    } = useFind({
      model: Product,
      params,
      fetchParams,
      paginate: true
    })

    return {
      options,
      sortBy,
      limit,
      products,
      queryObj,
      params,
      fetchParams,
      areProductsPending,
      haveProductsLoaded,
      paginationData
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    }
  },
  data: () => ({})
}
</script>
