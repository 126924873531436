<template lang="pug">
  v-card.mb-16(
    outlined
  )
    v-card-title
      v-layout(
        flex
      )
        h3.grey--text.text--darken-2 Products
        v-spacer
        v-text-field.flex-shrink-1.flex-grow-0(
          v-model="search"
          label="Search Products"
          single-line
          prepend-inner-icon="mdi-magnify"
          hide-details
          filled
          rounded
          dense
        )
    ProductsTable(
      :search="search"
      :query="{ shop_id: shop.id, type: 'SHOP'}"
      :shopId="shop.id"
    )
</template>

<script>
import { useGet } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

import ProductsTable from '@/components/tables/ProductsTable'

export default {
  name: 'ShopProducts',
  components: {
    ProductsTable
  },
  props: {
    shopId: {
      type: String,
      required: true
    }
  },
  setup (props, context) {
    const { Shop } = context.root.$FeathersVuex.api

    // Include this so we can join the region
    const params = computed(() => {
      return { query: { $eager: 'region' } }
    })

    const { item: shop, hasLoaded } = useGet({
      model: Shop,
      params,
      id: props.shopId
    })

    return {
      shop,
      hasLoaded
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    }
  },
  methods: {
    async deleteShop () {
      try {
        const message = 'Are you sure you want to delete this shop?'
        const isYes = await this.$confirm(message, { title: 'Warning' })

        if (isYes) {
          await this.shop.remove()
          this.$snackSuccess('Shop Deleted')
          this.$router.push({ name: 'shops' })
        }
      } catch (error) {
        this.$logError(error)
      }
    }
  },
  data: () => ({
    search: null
  })
}
</script>
